<section class="rounded-md bg-gray-50">
  <div class="mx-auto px-4 py-8">
    <div class="mb-8">
      <p class="mb-6 font-semibold text-brand-700">
        {{ data()?.sectionTitle }}
      </p>
      <h2 class="mb-6">{{ data()?.title }}</h2>
      <p class="text-xl text-gray-600">
        {{ data()?.text }}
      </p>
    </div>

    <div>
      <div class="flex w-full gap-4 px-2 py-6">
        <div
          class="size-10 min-w-10 rounded-lg"
          [uiBackgroundImage]="data()?.emailImage?.data?.attributes?.url"
        ></div>
        <div class="flex flex-col gap-2">
          <p class="text-xl font-semibold text-gray-900">
            {{ data()?.emailTitle }}
          </p>
          <p class="text-gray-600">{{ data()?.emailText }}</p>
          <a
            class="break-all font-semibold text-brand-700"
            [href]="'mailto:' + data()?.email"
            >{{ data()?.email }}</a
          >
        </div>
      </div>
      <div class="flex w-full gap-4 px-2 py-6">
        <div
          class="size-10 min-w-10 rounded-lg"
          [uiBackgroundImage]="data()?.phoneImage?.data?.attributes?.url"
        ></div>
        <div class="flex flex-col gap-2">
          <p class="text-xl font-semibold text-gray-900">
            {{ data()?.phoneTitle }}
          </p>
          <p class="text-gray-600">{{ data()?.phoneText }}</p>
          <a
            class="font-semibold text-brand-700"
            [href]="'tel:' + data()?.phone"
            >{{ data()?.phone }}</a
          >
        </div>
      </div>
    </div>
  </div>
</section>
