import { Injectable, inject } from '@angular/core';
import { AnalyticsService } from './analytics.service';
import { PlatformService } from '@util/platform.service';
import { BaseHrefService } from '@util/base-href.service';
import { ResolveEnd, Router } from '@angular/router';
import { filter, take, map, EMPTY, timeout } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsClickService {
  isBrowser$ = inject(PlatformService).isBrowser$;
  analyticsService = inject(AnalyticsService);
  baseHrefService = inject(BaseHrefService);
  router = inject(Router);

  public sendLinkClickEventByMouseEvent(
    linkTarget: HTMLElement,
    customText?: string,
  ): void {
    const href = this.getHref(linkTarget);
    const clickText = customText || this.getAnyTextFromElement(linkTarget) || '';

    if (href) {
      this.getClickEvent(href, clickText);
      return;
    }

    if (this.isBrowser$.value) {
      this.router.events
        .pipe(
          filter((ev): ev is ResolveEnd => ev instanceof ResolveEnd),
          take(1),
          map((ev) =>
            this.analyticsService.createInternalLinkClickEvent({
              destination_url: this.baseHrefService.getBaseHrefPathName() + ev?.urlAfterRedirects.substring(1),
              click_text: clickText,
            }),
          ),
          timeout({
            each: 1000,
            with: () => EMPTY,
          }),
        )
        .subscribe((event) => this.analyticsService.pushEvent(event));
    }
  }

  private getHref(el: HTMLElement): string | null {
    if (!el.hasAttribute('href')) return null;
    const href = el.getAttribute('href');
    if (!href || href.startsWith('#') || href.startsWith('javascript:void'))
      return null;
    return href;
  }

  private getClickEvent(href: string, clickText: string): void {
    if (!href) return;

    const hrefWithoutProtocol = href.replace(/^https?:\/\//, '');

    if (
      !href.startsWith('http') ||
      hrefWithoutProtocol.startsWith(this.baseHrefService.getBaseHrefHostName())
    ) {
      this.analyticsService.pushEvent(
        this.analyticsService.createInternalLinkClickEvent({
          destination_url: href,
          click_text: clickText,
        }),
      );
    } else {
      this.analyticsService.pushEvent(
        this.analyticsService.createExternalLinkClickEvent({
          destination_url: href,
          click_text: clickText,
        }),
      );
    }
  }

  private getAnyTextFromElement = (el: HTMLElement): string | null => {
    if (!el) return null;
  
    const text =
      el.innerText ||
      el.textContent ||
      el.getAttribute('aria-label') ||
      el.getAttribute('alt') ||
      el.getAttribute('title');
  
    if (!text && el.childElementCount === 1) {
      return this.getAnyTextFromElement(el.firstChild as HTMLElement);
    }
    return text;
  };
}


