import { Component, HostListener, inject } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { NavbarComponent } from './navbar/navbar.component';
import { AnalyticsService } from '@data/analytics/analytics.service';
import { combineLatest, distinctUntilChanged, filter, map } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { PlatformService } from '@util/platform.service';
import { AnalyticsClickService } from '@data/analytics/analytics-click.service';
import { AuthService } from '@util/auth.service';
import { AngularPlugin } from "@microsoft/applicationinsights-angularplugin-js";
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { environment } from "../environments/environment";

@Component({
  selector: 'app-root',
  standalone: true,
  templateUrl: './app.component.html',
  imports: [RouterOutlet, NavbarComponent],
})
export class AppComponent {
  analyticsService = inject(AnalyticsService);
  analyticsClickService = inject(AnalyticsClickService);
  isBrowser$ = inject(PlatformService).isBrowser$;
  authService = inject(AuthService);

  constructor(private router: Router) {
    this.configureApplicationInsights();

    if (this.isBrowser$.value) {
      combineLatest([
        this.authService.user$.pipe(
          takeUntilDestroyed(),
          distinctUntilChanged(),
        ),
        this.router.events.pipe(
          filter((e): e is NavigationEnd => e instanceof NavigationEnd),
        ),
      ])
        .pipe(map(([user, navigation]) => ({ user, navigation })))
        .subscribe(({ user, navigation }) => {
          this.analyticsService.pushEvent(
            this.analyticsService.createPageViewEvent({
              page_path: navigation.url,
              user_internal_traffic: (
                !!user && user?.role !== 'participant'
              ).toString(),
            }),
          );
        });
    }
  }

  @HostListener('window:mouseup', ['$event'])
  onMouseUp(event: MouseEvent): void {
    const click = event.button === 0;
    const middleClick = event.button === 1;
    if (!click && !middleClick) return;

    const clickTarget = event.target as Element;
    if (!clickTarget) return;

    const linkTarget = clickTarget.closest<HTMLElement>('a,button,svg');
    if (!linkTarget) return;

    this.analyticsClickService.sendLinkClickEventByMouseEvent(linkTarget);
  }

  private configureApplicationInsights(): void {
    if (this.isBrowser$.value) {
      if (environment.aiConnectionString) {
        const angularPlugin = new AngularPlugin();
        const appInsights = new ApplicationInsights({
          config: {
            connectionString: environment.aiConnectionString,
            extensions: [angularPlugin],
            extensionConfig: {
              [angularPlugin.identifier]: {router: this.router}
            }
          }
        });

        let telemetryInitializer = (envelope: any) => {
          envelope.tags["ai.cloud.role"] = "hpuk-app-ng";
        }

        appInsights.addTelemetryInitializer(telemetryInitializer);
        appInsights.loadAppInsights();
      }
    }
  }
}
